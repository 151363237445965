img {
    border-radius: 50%;
    margin-top: 40px;
    margin-bottom: auto
    ;
}

.aboutText {
    size: 10px;
    margin-top: 10%;
    text-align: center;
    
}

.container-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
@media (max-width: 767px) { 
    .aboutText {
        font-size: 20px;
        margin-top: 50%;
        text-align: center;
    }
}
