h3 {
  left: 0;
  line-height: 100px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
  
  }

p {
  text-align: center;
  
}

.navBar {
  background-color: #c7c3ae;
  align-items: left;
  text-align: left;
  top: 0;
  width: 100%;
  
}

.active {
  font-weight: bold;
}

body {
  padding-top: 60px;
}


