
.images {
  border-radius: 50%;
  margin-top: 10px;
}

/* .images:active{
  transition: transform 0.3s ease-in-out; 
  max-width: 100%;
  max-height: 100%;
  transform: scale(2.0);
  justify-content: center;
  align-items: center;
  display: flex;
} */

.images-stayActive{
  transform: scale(2.0);
  transition: transform 0.3s ease-in-out; 
  max-width: 100%;
  max-height: 100%;
  transform: scale(2.0);
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-gallery img {
  width: calc(33.33% - 20px); 
  max-width: 500px;
  height: auto;
  margin-bottom: 10px; 
  border-radius: 3px;
  
}

.image-gallery img:first-child {
  margin-left: 20px; /* Add margin to the left side of the first image */
}

.image-gallery img:last-child {
  margin-right: 20px; /* Add margin to the right side of the last image */
}

/* .image-gallery img:hover
{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
} */

.line-with-header {
  display: flex;
  align-items: center;
}

.image-placeholder {
  width: calc(33.33% - 20px); /* Adjust the percentage and spacing as needed */
  max-width: 500px; /* Adjust the maximum width as needed */
  height: auto;
  margin-bottom: 10px; /* Adjust the spacing between rows */
  box-sizing: border-box;
  margin-left: 20px;
}

.header-title {
  margin-left: 20px;
  margin-top: 10px;
}

.line {
  flex-direction:right;
  flex-grow: 0.90;
  border-top: 2px solid #000;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.container {
  padding-top:80px;
}

.image-gallery-clicked img {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20x;
  width: calc(100% - 40px);
  height: auto;
  border-radius: 3px;
  }

@media (max-width: 767px) { 
.image-gallery img {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20x;
  width: calc(100% - 40px);
  height: auto;
  border-radius: 3px;
  }

/* .image-gallery img:hover
{
    -webkit-transform: scale(1.1, 1.3);
    -moz-transform: scale(1.1, 1.3);
    -o-transform: scale(1.1,1.3);
    -ms-transform: scale(1.1, 1.3);
    transform: scale(1.1, 1.3);
} */

.header-title-clicked {
  font-size: small;
} 
}

